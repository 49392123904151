export default {
    "props": {
        "newsItemDateProps": {
            "variant": "caption",
            "component": "p",
            "marginBottom": "10px",
        },
    },
    "sx": (theme) => ({
        "& .newsDetails-content": {
            [theme.breakpoints.up('md')]: {
                "marginBottom": theme.spacing(7),
            },
            "& img": {
                "width": "100%",
                "height": "auto",
            },
        },
    }),
}