import React from 'react';
import GlobalLayout from "../../GlobalLayout";
import _ from "lodash";
import { MyaccountStoreProvider, useMyaccountState } from "../../../../services";
import MyAccountNewsDetails from '../../blog/details';

const NewsDetails = ({slug}) => {
    const { state, services } = useMyaccountState();
    const newsItemData =  state?.newsItems || {};

    React.useEffect(() => {
        services.getNewsItems({id:slug})
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const newsItem = _.get(newsItemData, '0'); // get the first element

    return(
        <MyAccountNewsDetails
            data={newsItem}
        />
    )
}

const NewsDetailsPageWrap = ({slug}) => {
    return (
        <MyaccountStoreProvider>
            <NewsDetails slug={slug} />
        </MyaccountStoreProvider>
    )
}

const NewsDetailsPage = (props) => {
    return (
        <GlobalLayout>
            <NewsDetailsPageWrap {...props} />
        </GlobalLayout>
    )
}


export default React.memo(NewsDetailsPage)
