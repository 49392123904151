export default {
    "props": {
        "pageTitle": "News",
        "loadMoreBtnProp": {
            "variant": "contained",
            "className": "load-more-btn",
        },
        "api": {
            "linkToWebsite": false,
            "endpoint": "blogs",
            "limit": 9,
            "query" :{
                _sort:'publish_start_date:DESC'
            },
            "fieldMapping": {
                title: 'title',
                publish_start_date: 'publish_start_date',
                intro_image: 'thumbnail_url',
                introtext: 'introtext',
                tag: 'tag',
                category: 'category',
                baseUrl: '',
                urlLevel: 1,
                urlLevel_1: 'id'
            }
        },
        "loadMoreBtnLabel": "Show more",
        "_data": [
            {
                "id": "ghf",
                "published": true,
                "title": "This is the news title",
                "createdAt": "2020-12-18T13:54:11.125Z",
                "tile_image": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210070_45.jpg",
                "intro_image_alt": "This is alt text",
                "banner_image": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210070_45.jpg",
                "image_alt": "This is main banner image",
                "description": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
                "introtext": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica, Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
            },
            {
                "id": "ccvb",
                "published": true,
                "title": "This is the news title",
                "createdAt": "2020-12-16T13:54:11.125Z",
                "tile_image": "https://assets.reapit.net/aac/live/pictures/EDI/20/EDI200459_29.jpg",
                "intro_image_alt": "This is alt text",
                "banner_image": "https://assets.reapit.net/aac/live/pictures/EDI/20/EDI200459_29.jpg",
                "image_alt": "This is main banner image",
                "description": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
                "introtext": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica, Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
            },
            {
                "id": "asdasd",
                "published": true,
                "title": "This is the news title",
                "createdAt": "2020-12-13T13:54:11.125Z",
                "tile_image": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210495_35.jpg",
                "intro_image_alt": "This is alt text",
                "banner_image": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210495_35.jpg",
                "image_alt": "This is main banner image",
                "description": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
                "introtext": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica, Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
            },
            {
                "id": "hgds",
                "published": true,
                "title": "This is the news title",
                "createdAt": "2020-12-18T13:54:11.125Z",
                "tile_image": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210070_45.jpg",
                "intro_image_alt": "This is alt text",
                "banner_image": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210070_45.jpg",
                "image_alt": "This is main banner image",
                "description": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
                "introtext": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica, Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
            },
            {
                "id": "ccvarb",
                "published": true,
                "title": "This is the news title",
                "createdAt": "2020-12-16T13:54:11.125Z",
                "tile_image": "https://assets.reapit.net/aac/live/pictures/EDI/20/EDI200459_29.jpg",
                "intro_image_alt": "This is alt text",
                "banner_image": "https://assets.reapit.net/aac/live/pictures/EDI/20/EDI200459_29.jpg",
                "image_alt": "This is main banner image",
                "description": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
                "introtext": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica, Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
            },
            {
                "id": "cv",
                "published": true,
                "title": "This is the news title",
                "createdAt": "2020-12-13T13:54:11.125Z",
                "tile_image": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210495_35.jpg",
                "intro_image_alt": "This is alt text",
                "banner_image": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210495_35.jpg",
                "image_alt": "This is main banner image",
                "description": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica. Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
                "introtext": "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica, Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
            }
        ]
    },
    "sx": (theme) => ({
        // "background": "black"
        "& .load-more-btn": {
            "margin": theme.spacing(4, 0, 4)
        },
    })
}
