export default {
    "props": {
        "buttonProp": {
            "variant": "text",
        },
        "btnLabel": " Back to results",
        "setIcon": "backButtonArrow",
        "iconProps": {

        },
    },
    "sx": (theme) => ({
        "display": "none",
        "width": "auto",
        "padding": 0,
        "color": theme.palette.text.primary,
        "marginLeft": "-5px",
        "marginBottom": "5px",
        "&:hover": {
            "background": "none",
        },
        [theme.breakpoints.up('md')]: {
            "display": "inline-flex",
        },
    }),
}