export default {
    "props": {
        
    },
    "sx": (theme) => ({
        "backgroundSize": "cover",
        "backgroundRepeat": "no-repeat",
        "backgroundPosition": "center center",
        "width": "100%",
        "height": "250px",
        "height": "350px",
        "marginBottom": theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            "height": "250px",
        },
        [theme.breakpoints.up('lg')]: {
            "height": "450px",
        },
    })
}