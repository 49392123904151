export default {
    "props": {
        "newsItemProps": {
            "item": true,
            "xs": 12,
            "md": 6,
            "lg": 4,
        },
        "newsItemCardProps": {
            "elevation": 2,
        },
        "newsItemImageProps": {
            "component": "img",
        },
        "newsItemTitleProps": {
            "gutterBottom": true,
            "component": "h2",
            "variant": "h6",
        },
        "newsItemDateShow": true,
        "newsItemDateProps": {
            "variant": "caption",
            "component": "p",
            "marginBottom": "10px",
        },
        "newsItemIntroLimit": 250,
        "newsItemIntroProps": {
            "variant": "body2",
            "color": "text.secondary",
        },
        "newsItemActionsBtnProps": {
            "size": "small",
            "variant": "text",
        },
        "newsItemActionsBtnIconProps": {
            "fontSize": "inherit",
        },
    },
    "sx": (theme) => ({
        "marginBottom": theme.spacing(3.5),
        [theme.breakpoints.up('md')]: {
            "marginBottom": theme.spacing(7),
        },
        "& .newsItemCard": {
            "height": "100%",
            "& .MuiCardActionArea-focusHighlight": {
                display: "none",
            },
            "&:hover": {
                backgroundColor: "rgba(28, 28, 28, .1)"
            }
        },
        "& .newsItemImageContainer": {
            "overflow": "hidden",
        },
        "& .newsItemImage": {
            // "height": "250px",
            "transform": "scale(1)",
            "transition": "transform .2s ease-out",
            display: "block",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            objectFit: "cover",
            "&:hover": {
                "transform": "scale(1.3)",
            }
        },
        "& .newsItemContent": {

        },
        "& .newsItemActions": {
            "& .newsItemActionsBtnIcon": {
                "marginRight": "5px",
            },
        },
    })
}
