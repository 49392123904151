import React from 'react';
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import Paper from "@mui/material/Paper"

const ItemSkeleton = () => (
    <Paper elevation={2}>
        <Stack direction="column" spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={170} />
            <Stack direction="column" spacing={2} px={2} pb={2}>
                <Skeleton variant="text" width="70%" height={25} />
                <Skeleton variant="text" width="30%" height={25} />
                <Skeleton variant="text" width={85} height={15} />
                <Skeleton variant="rectangular" width="80%" height={40} />
            </Stack>
        </Stack>
    </Paper>
)

export default ItemSkeleton;
